import cn from 'classnames';

import { marker, markerSelected } from 'components/colors';

import Svg from './Svg';
import { Solid as Star } from './Star';

export const Pin = props => (
  <Svg {...props}>
    <g transform="translate(0 -1028.4)">
      <path
        d="m12 0c-4.4183 2.3685e-15 -8 3.5817-8 8 0 1.421 0.3816 2.75 1.0312 3.906 0.1079 0.192 0.221 0.381 0.3438 0.563l6.625 11.531 6.625-11.531c0.102-0.151 0.19-0.311 0.281-0.469l0.063-0.094c0.649-1.156 1.031-2.485 1.031-3.906 0-4.4183-3.582-8-8-8zm0 4c2.209 0 4 1.7909 4 4 0 2.209-1.791 4-4 4-2.2091 0-4-1.791-4-4 0-2.2091 1.7909-4 4-4z"
        transform="translate(0 1028.4)"
      />
    </g>
    <circle cx="12" cy="8" r="5" className="text-white opacity-75" />
  </Svg>
);

export const Circle = props => (
  <Svg content={<circle cx="12" cy="12" r={props.size / 2} />} {...props} />
);

export const Icon = ({ index, selected, variant, ...props }) => {
  const className = cn({
    [markerSelected[index]]: selected,
    [marker[index]]: !selected
  });
  const innerProps = { className, ...props };

  if (variant === 'pin') return <Pin {...innerProps} />;
  if (variant === 'circle') return <Circle {...innerProps} />;
  if (variant === 'star') return <Star {...innerProps} />;
};

Icon.defaultProps = {
  variant: 'circle'
};

export default Icon;
