function Svg({ children, className, content, size, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={`fill-current ${className}`}
      {...props}
    >
      {content || children}
    </svg>
  );
}

const MemoSvg = React.memo(Svg);
export default MemoSvg;
