const Container = React.forwardRef(
  ({ children, isOpen, onClick, wrapper: Wrapper }, ref) => (
    <div className="relative border-b-2" onClick={onClick} ref={ref}>
      {!isOpen && children}
      {isOpen && <Wrapper>{children}</Wrapper>}
    </div>
  )
);

Container.defaultProps = {
  wrapper: React.Fragment
};

export default Container;
