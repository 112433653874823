import theme from 'utils/theme';

export const DESKTOP = `@media (min-width: ${theme.screens.md})`;

const { colors, fontSize, padding } = theme;
const fontFamily = theme.fontFamily.sans.join(', ');

const customStyles = {
  control: provided => ({
    ...provided,
    backgroundColor: colors.gray[200],
    borderRadius: 0,
    borderWidth: 0,
    boxShadow: `0 0 0 0 !important`,
    cursor: 'pointer',
    fontFamily,
    fontSize: fontSize.sm,
    fontWeight: theme.fontWeight.semibold,
    '&:hover': {
      boxShadow: `0 0 0 0 !important`
    },
    [DESKTOP]: {
      borderRadius: theme.borderRadius.sm
    }
  }),
  clearIndicator: provided => ({
    ...provided,
    color: colors.gray[500],
    paddingLeft: padding[2],
    paddingRight: padding[1],
    '&:hover': {
      color: colors.gray[600]
    }
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: colors.gray[500],
    paddingLeft: padding[0],
    paddingRight: padding[2],
    '&:hover': {
      color: colors.gray[600]
    }
  }),
  group: provided => ({
    ...provided,
    padding: 0
  }),
  groupHeading: provided => ({
    ...provided,
    backgroundColor: colors.gray[100],
    borderColor: colors.gray[200],
    borderWidth: '1px 0 1px 0',
    color: colors.gray[600],
    fontWeight: theme.fontWeight.semibold,
    fontSize: theme.fontSize['sm'],
    marginTop: padding[1],
    paddingBottom: padding[1],
    paddingLeft: padding[3],
    paddingRight: padding[1],
    paddingTop: padding[1]
  }),
  indicatorSeparator: () => null, // Hide indicator
  option: (provided, { isFocused, ...state }) => {
    return {
      ...provided,
      backgroundColor: isFocused ? colors.gray[300] : 'transparent',
      color: colors.gray[900],
      cursor: 'pointer',
      fontFamily,
      fontSize: fontSize.sm,
      paddingBottom: padding[3],
      paddingLeft: padding[3],
      paddingRight: padding[1],
      paddingTop: padding[4],
      [DESKTOP]: {
        paddingBottom: padding[2],
        paddingTop: padding[2]
      },
      '&:active': {
        backgroundColor: colors.gray[400]
      }
    };
  },
  menuList: provided => ({
    ...provided,
    boxShadow: theme.boxShadow['2xl']
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: colors.gray[200],
    cursor: 'normal',
    fontFamily,
    fontSize: fontSize.md,
    fontWeight: theme.fontWeight.medium,
    paddingBottom: padding[0],
    paddingRight: padding[1],
    paddingTop: padding[0],
    zIndex: theme.zIndex[50]
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: state.selectProps.menuIsOpen ? colors.gray[500] : colors.gray[900],
    fontSize: fontSize.sm
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    backgroundColor: colors.gray[300],
    color: state.selectProps.menuIsOpen ? colors.gray[400] : colors.gray[500],
    padding: padding[1],
    '&:hover': {
      backgroundColor: colors.red[300]
    }
  }),
  placeholder: provided => ({
    ...provided,

    color: colors.gray[700]
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.selectProps.menuIsOpen ? colors.gray[500] : colors.gray[900],
    fontSize: fontSize.sm,
    fontWeight: theme.fontWeight.medium
  }),
  valueContainer: provided => ({
    ...provided,
    paddingBottom: padding[2],
    paddingRight: 0,
    paddingTop: padding[2],
    [DESKTOP]: {
      paddingBottom: padding[3],
      paddingTop: padding[3]
    }
  })
};

export default customStyles;
