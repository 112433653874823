export const colors = ['pink', 'teal', 'orange', 'purple', 'blue'];

export const badge = [
  'bg-pink-400',
  'bg-teal-400',
  'bg-orange-400',
  'bg-purple-400',
  'bg-blue-500'
];

export const bubble = [
  'border-pink-400 bg-pink-300',
  'border-teal-400 bg-teal-300',
  'border-orange-400 bg-orange-300',
  'border-purple-400 bg-purple-300',
  'border-blue-400 bg-blue-300'
];

export const cluster = [
  'bg-pink-500 border-pink-500',
  'bg-teal-500 border-teal-500',
  'bg-orange-500 border-orange-500',
  'bg-purple-500 border-purple-500',
  'bg-blue-500 border-blue-500'
];

export const marker = [
  'text-pink-400',
  'text-teal-400',
  'text-orange-400',
  'text-purple-400',
  'text-blue-400'
];

export const markerSelected = [
  'text-pink-500',
  'text-teal-500',
  'text-orange-500',
  'text-purple-500',
  'text-blue-500'
];
