import { useEffect, useState, useCallback } from 'react';
import ReactSelect, { components as ReactSelectComponents } from 'react-select';

import { badge as colorMap } from 'components/colors';

import Container from './Container';
import customStyles from './customStyles';

// Wraps react-select for a better search filter. Matches all words searched
// anywhere in option.label (case-insensitive).
const searchFilter = (option, input) => {
  const words = input.split(' ');
  const label = option && option.label.toLowerCase();
  return words.reduce(
    (acc, cur) => acc && label.includes(cur.toLowerCase()),
    true
  );
};

const MultiValueLabel = ({ ...props }) => {
  const i = props.selectProps.value.findIndex(o => o.label === props.children);
  const bgColor = colorMap[i];
  return (
    <div className="flex items-center cursor-auto overflow-hidden">
      <span className={`w-2 h-2 rounded-full -mt-1px ${bgColor}`} />
      <ReactSelectComponents.MultiValueLabel {...props} />
    </div>
  );
};

const ValueContainer = ({
  onClick,
  Container = ReactSelectComponents.ValueContainer,
  ...props
}) => (
  <>
    <div
      className="absolute w-full h-full z-50 cursor-pointer"
      onTouchEnd={onClick}
      onClick={onClick}
    />
    {/* Setting width of Container fixes an overflow issue with long names.
        Width was selected arbitrarily. */}
    <Container {...props} className="w-64" />
  </>
);

const Select = ({
  components,
  customDropdownWrapper,
  styles,
  onChange,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);
  const containerRef = React.useRef(null);

  // Handle selection of a dropdown list item
  const handleChange = item => {
    onChange(item || undefined);
    setOpen(false);
  };

  const handleDocumentClick = useCallback(e => {
    // Close menu when clicking outside the dropdown
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setOpen(false);
    }
  });

  const handleKeyDown = useCallback(e => {
    if (e.key === 'Escape') {
      setOpen(false);
    } else if (!open) {
      setOpen(true);
    }
  });

  const handleValueContainerClick = useCallback(() => toggleOpen());

  // Listen for clicks to close menu when interacting outside dropdown
  useEffect(() => {
    document.addEventListener('mousedown', handleDocumentClick);
    return () => document.removeEventListener('mousedown', handleDocumentClick);
  }, []);

  return (
    <Container isOpen={open} ref={containerRef} wrapper={customDropdownWrapper}>
      <ReactSelect
        components={{
          ...components,
          MultiValueLabel,
          ValueContainer: props => (
            <ValueContainer
              {...props}
              onClick={handleValueContainerClick}
              Container={components.ValueContainer}
            />
          )
        }}
        filterOption={searchFilter}
        isClearable={false}
        menuIsOpen={open}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        styles={{ ...styles, ...customStyles }}
        autoFocus
        {...props}
      />
    </Container>
  );
};

export default React.memo(Select);
